import { defineComponent } from 'vue';
import TitleBanner from '@/components/TitleBanner.vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'Batch',
    setup() {
        const { t } = useI18n();
        return {
            t,
        };
    },
    components: { TitleBanner },
});
