import { defineComponent } from 'vue';
export default defineComponent({
    name: 'TitleBanner',
    props: {
        title: {
            type: String,
            required: false,
        },
        support: {
            type: Boolean,
            required: false,
            default: true,
        },
        inline: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
});
